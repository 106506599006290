import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "Download",
    component: () => import("../views/Download/Download"),
  },
];
// https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0832655e1efd4edf&redirect_uri=http%3A%2F%2Ftest.all-ctrl.com&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
// https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx0832655e1efd4edf&redirect_uri=http%3A%2F%2Fapp.all-ctrl.com&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // base: "./download",
});
// router.beforeEach(async (to) => {
//   if (to.meta.title) { // 判断是否有标题
//     document.title = to.meta.title;
//   }
// });
export default router;
