<template>
  <router-view/>
</template>

<style>
html,body{
  margin: 0;
  padding:0;
  width: 100%;
  height: 100%;
}
#app {
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
}
</style>
